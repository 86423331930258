import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import _ from "lodash";
import Alert from "sweetalert2/dist/sweetalert2.js";
import SolanaServices from "@/core/services/SolanaServices";

const guess = [
  // '/auth',
  "/sign-in",
  "/forgot-password",
  "/reset-password",
];

const client_site = [
  "/home",
  "/detalle",
  "/cita-en-agencia",
  "/seleccionar-cita",
  "/confirmar-cita",
  '/catalogo',
  '/agendar-cita',
  '/vender-auto',
  '/apartar-auto',
  '/resumen-apartado'
]
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/dashboard/Dashboard.vue"),
        async beforeEnter(to, from, next) {
          const {data} = await SolanaServices.getTipoRol();
          const tipoRol = data.data[0].type;
          if (tipoRol == 'Múltiples agencias') {
            const agencyIdSelected = await localStorage.getItem("agencyIdSelected");
            next("/dashboard/"+agencyIdSelected);
          } else if(tipoRol == 'Agencia' || tipoRol == 'Ciudad'){
            next("/calendario");
          }else {
            next();
          }
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/dashboard/:id",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard/:id",
        name: "dashboard-detail",
        component: () => import("@/views/dashboard/DashboardDetail.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/agencies",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/agencies",
        name: "Agencias",
        component: () => import("@/views/agencies/AgenciesView.vue"),
        async beforeEnter(to, from, next) {
          if (can("Ver listado de agencias")) {
            next();
          } else {
            await alertError();
            next("/dashboard");
          }
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/agencies/:id",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/agencies/:id",
        name: "agencies-detail",
        component: () => import("@/views/agencies/AgencyDetail.vue"),
        async beforeEnter(to, from, next) {
          if (can("Ver detalle de agencia")) {
            next();
          } else {
            await alertError();
            next("/dashboard");
          }
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/vehicles/:id?",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/vehicles/:id?",
        name: "Vehículos",
        component: () => import("@/views/vehicles/VehiclesView.vue"),
        async beforeEnter(to, from, next) {
          if (can("Ver listado de vehículos")) {
            next();
          } else {
            await alertError();
            next("/dashboard");
          }
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/vehicles-filters/:filter?/:agency?",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/vehicles-filters/:filter?/:agency?",
        name: "vehicles-filters",
        component: () => import("@/views/vehicles/VehiclesView.vue"),
        async beforeEnter(to, from, next) {
          if (can("Ver listado de vehículos")) {
            next();
          } else {
            await alertError();
            next("/dashboard");
          }
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/vehicles/:id",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/vehicles/:id",
        name: "vehicles-detail",
        component: () => import("@/views/vehicles/VehicleDetail.vue"),
        async beforeEnter(to, from, next) {
          if (can("Ver detalle de vehículo")) {
            next();
          } else {
            await alertError();
            next("/dashboard");
          }
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/nevehicle/:id?",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/nevehicle/:id?",
        name: "new-edit-vehicles",
        component: () => import("@/views/vehicles/NewVehicle.vue"),
        async beforeEnter(to, from, next) {
          if (can("Crear vehículo")) {
            next();
          } else {
            await alertError();
            next("/dashboard");
          }
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/apartados/:id?",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/apartados/:id?",
        name: "Apartados",
        component: () => import("@/views/apartados/ApartadosView.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/citas",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/citas",
        name: "Citas",
        component: () => import("@/views/citas/CitasView.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/citas-filters/:tipo?/:status?/:agencia?",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/citas-filters/:tipo?/:status?/:agencia?",
        name: "citas-filters",
        component: () => import("@/views/citas/CitasView.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/appraisals/:idt?",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/appraisals/:idt?",
        name: "Avalúos a domicilio",
        component: () => import("@/views/appraisals/List.vue"),
        async beforeEnter(to, from, next) {
          if( can("Ver listado de avalúos") || can("Ver listado de avalúos a domicilio") || can("Ver listado de avalúos en agencia")) {
            next();
          } else {
            await alertError();
            next("/dashboard");
          }
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/appraisals-filters/:filter?",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/appraisals-filters/:filter?",
        name: "appraisals-filters",
        component: () => import("@/views/appraisals/List.vue"),
        async beforeEnter(to, from, next) {
          if( can("Ver listado de avalúos") || can("Ver listado de avalúos a domicilio") || can("Ver listado de avalúos en agencia")) {
            next();
          } else {
            await alertError();
            next("/dashboard");
          }
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/appraisals/:id",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/appraisals/:id",
        name: "appraisals-detail",
        component: () => import("@/views/appraisals/Detail.vue"),
        async beforeEnter(to, from, next) {
          if( can("Ver listado de avalúos") || can("Ver listado de avalúos a domicilio") || can("Ver listado de avalúos en agencia")) {
            next();
          } else {
            await alertError();
            next("/dashboard");
          }
        },
      },
    ],
  },
  
  {
    path: "/",
    redirect: "/avaluos",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/avaluos",
        name: "Avaluos",
        component: () => import("@/views/avaluos/AvaluosView.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/leads/:id?",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/leads/:id?",
        name: "Leads",
        component: () => import("@/views/leads/LeadsView.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/leads/:tipo?/:agencia?",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/leads/:tipo?/:agencia?",
        name: "leads-filters",
        component: () => import("@/views/leads/LeadsView.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/apartados/:uuid",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/apartados/:uuid",
        name: "apartados-detail",
        component: () => import("@/views/apartados/ApartadoDetail.vue"),
        async beforeEnter(to, from, next) {
          //Cambiar permiso
          if (can("Ver detalle de vehículo")) {
            next();
          } else {
            await alertError();
            next("/dashboard");
          }
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/users",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/users",
        name: "Users",
        component: () => import("@/views/colaboradores/UsersView.vue"),
        async beforeEnter(to, from, next) {
          if (can("Ver listado de usuarios")) {
            next();
          } else {
            await alertError();
            next("/dashboard");
          }
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/roles",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/roles",
        name: "roles",
        component: () => import("@/views/roles/RolesList.vue"),
        async beforeEnter(to, from, next) {
          if (can("Ver listado de roles")) {
            next();
          } else {
            await alertError();
            next("/dashboard");
          }
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/roles/:id",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/roles/:id",
        name: "role-detail",
        component: () => import("@/views/roles/RoleDetail.vue"),
        async beforeEnter(to, from, next) {
          if (can("Ver detalle de rol")) {
            next();
          } else {
            await alertError();
            next("/dashboard");
          }
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/config",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/config",
        name: "Config",
        component: () => import("@/views/config/ConfigView.vue"),
        async beforeEnter(to, from, next) {
          if (can("Ver detalle de agencia")) {
            next();
          } else {
            await alertError();
            next("/dashboard");
          }
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/calendario/:id?",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/calendario/:id?",
        name: "Calendario",
        component: () => import("@/views/agenda/agendaView.vue"),
        async beforeEnter(to, from, next) {
          if (can("Ver calendario") || can("Ver detalle de cita a domicilio")) {
            next();
          } else {
            await alertError();
            next("/dashboard");
          }
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/config-dates",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/config-dates",
        name: "ConfigDates",
        component: () =>
          import(
            "@/views/colaboradores/schedule_address/ScheduleAddressView.vue"
          ),
        async beforeEnter(to, from, next) {
          if (can("Configurar agenda a domicilio")) {
            next();
          } else {
            await alertError();
            next("/dashboard");
          }
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/reset-password/:token/:pwd?",
        name: "reset-password",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordSet.vue"),
      },
    ],
  },
  {
    // Client - site
    path: "/",
    component: () => import("@/layout/client/ClientLayout.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        component: () =>
          import("@/views/client/Home.vue"),
      },
      {
        path: "/catalogo",
        name: "catalogo",
        component: () =>
          import("@/views/client/Catalogo.vue"),
      },
      {
        path: "/apartar-auto/:id/:error?",
        name: "apartar-vehicle",
        component: () =>
          import("@/views/client/ApartarVehicle/ApartarVehicle.vue"),
      },
      {
        path: "/detalle/:id",
        name: "car-detail",
        component: () =>
          import("@/views/client/DetalleVehiculo.vue"),
      },
      {
        path: "/resumen-apartado/:id",
        name: "resumen-apartado",
        component: () =>
          import("@/views/client/ApartarVehicle/ResumenApartado.vue"),
      },
      {
        path: "/agendar-cita/:id",
        name: "schedule-appointment",
        component: () =>
          import("@/views/client/appointment/ScheduleAppointment.vue"),
      },
      {
        path: "/agendar-cita",
        name: "ScheduleType",
        component: () =>
          import("@/views/schedule/ScheduleType.vue"),
      },
      {
        path: "/vender-auto/:id?",
        name: "sell-vehicle",
        component: () =>
          import("@/views/client/SellVehicle/SellVehicle.vue"),
      },

      {
        path: "/cita-en-agencia",
        name: "AgencyAppointment",
        component: () =>
          import("@/views/schedule/AgencyAppointment.vue"),
      },
      {
        path: "/confirmar-cita/:id?",
        name: "ScheduleSuccess",
        component: () =>
          import("@/views/schedule/ScheduleSuccess.vue"),
      },
      {
        path: "/seleccionar-cita/:type?",
        name: "Schedule",
        component: () =>
          import("@/views/schedule/Schedule.vue"),
        props: true
      },
    ]
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

const alertError = async () => {
  await Alert.fire({
    icon: "warning",
    title: "¡No autorizado!",
    text: "Usted no tiene permisos para acceder a este modulo.",
    confirmButtonText: "Aceptar",
  });
};

const can = (permissions) => {
  const Permissions = store.getters.getPermissions;
  if (_.size(Permissions)) {
    if (Array.isArray(permissions)) {
      let flag = false;
      _.map(permissions, (permission) => {
        if (Permissions.indexOf(permission) !== -1) {
          flag = true;
        }
      });
      return flag;
    } else {
      return Permissions.indexOf(permissions) !== -1;
    }
  }
  return false;
};

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH);

  const isGuess = _.find(guess, function (item) {
    return _.includes(to.path, item);
  });
  const isClient = _.find(client_site, function (item) {
    return _.includes(to.path, item);
  });

  const auth = store.getters.isUserAuthenticated;
  const redirect = "/home";
  if (isGuess) {
    if (auth) {
      next("/dashboard");
    } else {
      next();
    }
  } else if(isClient){
    next();
  }else{
    if (auth) {
      next();
    } else {
      next(redirect);
    }
  }
  // Scroll page to top on every route change
  setTimeout(() => {
    if(!to.path.includes('/catalogo'))
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
