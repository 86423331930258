import { createApp } from "vue";
import App from "./App.vue";
import 'v-calendar/dist/style.css';
/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
//import router from "./router";
import router from "@/router/clean";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import Maska from "maska";
import Permissions from "@/core/helpers/Permissions.vue";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import es from 'element-plus/es/locale/lang/es';
import "@/core/plugins/prismjs";
import "bootstrap";

import Datepicker from '@vuepic/vue-datepicker';

//Import maska

const app = createApp(App);

// app.mixin(Permissions);
app.mixin(Permissions);
app.use(store);
app.use(router);
app.use(ElementPlus,{locale: es,});
app.use(Maska);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);
app.component('Datepicker', Datepicker);
app.mount("#app");
